import numUtils from "./numUtils.js";

const getTokenIdFromStrikePECE = (indexName, instruments, strike, peCe) => {
  let resultInstrument;
  for (let key in instruments) {
    let instrument = instruments[key];
    if (
      indexName === instrument.name &&
      strike == instrument.strike &&
      peCe === instrument.instrumentType
    ) {
      resultInstrument = instrument;
      break;
    }
  }
  return resultInstrument?.exchangeToken;
};

const getNearestStrikePrice = (price, nearestMultiple = 100) => {
  let inputPrice = numUtils.getToFixedNumber(price);
  let remainder = inputPrice % nearestMultiple;
  if (remainder < parseInt(nearestMultiple / 2)) {
    return inputPrice - remainder;
  } else {
    return inputPrice + (nearestMultiple - remainder);
  }
};

const getStrikesEitherSide = (
  atmStrike,
  nearestMultiple = 100,
  noStrikesEitherSide
) => {
  let allStrikes = [atmStrike];
  let ceStrike = atmStrike;
  let peStrike = atmStrike;
  for (let i = 0; i < noStrikesEitherSide; i++) {
    ceStrike += nearestMultiple;
    peStrike -= nearestMultiple;
    allStrikes.push(ceStrike);
    allStrikes.push(peStrike);
  }
  return allStrikes.sort();
};

const fnoUtils = {
  getTokenIdFromStrikePECE,
  getNearestStrikePrice,
  getStrikesEitherSide,
};

export default fnoUtils;
