const ROUTES = {
  HOME: "/",
  TURBO: "/turbo",
  SIGNUP: "/signup",
  LOGIN: "/login",
  LOGOUT: "/logout",
  FORGOT_PASSWORD: "/forgot-password",
  DASHBOARD: "/dashboard",
  SETTINGS: "/settings",
  FAQ: "/faq",
  ABOUT: "/about",
  HELP: "/help",
  PRIVACY: "/privacy",
  TERMS: "/terms",
};

export default ROUTES;
