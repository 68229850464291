import { Routes, Route } from "react-router-dom";
import { Layout, theme } from "antd";
import LoginComponent from "../LoginComponent/index.js";
import TurboComponent from "../TurboComponent/index.js";
import HomepageComponent from "../HomepageComponent/index.js";
import SettingsComponent from "../SettingsComponent/index.js";

const { Content } = Layout;

const MainComponent = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Content style={{ padding: "0 10px" }}>
      <div
        className="site-layout-content"
        style={{
          backgroundImage:
            "linear-gradient(to bottom, #ffffff, #fbfbfb, #f6f6f6, #f2f2f2, #eeeeee)",
        }}
      >
        <Routes>
          <Route path="/" exact element={<HomepageComponent />} />
          <Route path="/login" element={<LoginComponent />} />
          <Route path="/turbo" element={<TurboComponent />} />
          <Route path="/settings" element={<SettingsComponent />} />
        </Routes>
      </div>
    </Content>
  );
};

export default MainComponent;
