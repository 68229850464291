import { Layout } from "antd";
const { Footer } = Layout;

const FooterComponent = () => {
  return null;
  // <Footer
  //   style={{
  //     textAlign: "center",
  //   }}
  // >
  //   Velociax ©2023 Created by Vish!
  // </Footer>
};

export default FooterComponent;
