const getToFixedNumber = (num, fixedDigits) => {
  if (!num) {
    return num;
  }
  if (fixedDigits === 0) {
    return parseInt(+num);
  }
  fixedDigits = fixedDigits || 2;
  return +num.toFixed(fixedDigits);
};

const getTime = (ts, brokerid) => {
  if (brokerid === 2) {
    let timePart = ts.split(" ")[0];
    return timePart;
  }
  let date = new Date(ts);
  return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};

const numUtils = {
  getToFixedNumber,
  getTime,
};

export default numUtils;
