import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  TrademarkOutlined,
  HomeOutlined,
  SettingOutlined,
  LoginOutlined,
  RiseOutlined,
} from "@ant-design/icons";

const { Header } = Layout;

const items = [
  {
    label: <Link to="/">Home</Link>,
    key: "home",
    icon: <HomeOutlined />,
  },
  {
    label: "Signup",
    key: "signup",
    icon: <TrademarkOutlined />,
    disabled: true,
  },
  {
    label: <Link to="/login">Login</Link>,
    key: "login",
    icon: <LoginOutlined />,
  },
  {
    label: <Link to="/turbo">Turbo</Link>,
    key: "turbo",
    icon: <RiseOutlined />,
  },
  {
    label: <Link to="/settings">Settings</Link>,
    key: "settings",
    icon: <SettingOutlined />,
  },
  // {
  //   label: "Navigation Three - Submenu",
  //   key: "SubMenu",
  //   icon: <SettingOutlined />,
  //   children: [
  //     {
  //       type: "group",
  //       label: "Item 1",
  //       children: [
  //         {
  //           label: "Option 1",
  //           key: "setting:1",
  //         },
  //         {
  //           label: "Option 2",
  //           key: "setting:2",
  //         },
  //       ],
  //     },
  //     {
  //       type: "group",
  //       label: "Item 2",
  //       children: [
  //         {
  //           label: "Option 3",
  //           key: "setting:3",
  //         },
  //         {
  //           label: "Option 4",
  //           key: "setting:4",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

function HeaderComponent() {
  return (
    <Header>
      <div className="logo" />
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={["1"]}
        items={items}
      />
    </Header>
  );
}

export default HeaderComponent;
