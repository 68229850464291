import { useEffect, useState } from "react";
import io from "socket.io-client";
import storage from "../../utils/storage.js";
import positionUtils from "../../utils/positionUtils.js";
import numUtils from "../../utils/numUtils.js";
import userUtils from "../../utils/userUtils.js";
import AuthApi, { setVelociaxTurboKey } from "../../api/auth.js";
import {
  Col,
  Row,
  Select,
  Space,
  Button,
  Radio,
  Checkbox,
  Table,
  Input,
  Progress,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import fnoUtils from "../../utils/fnoUtils.js";
import "./index.css";

let socket;

const DEFAULT_SELECTS = {
  instrument: "SENSEX",
  expiry: "23N16",
  productType: "NRML",
  execValue: "exec-straddle",
  execIndivLegs: false,
  ADD_QTY_PRESELECT_LOTS: [20, 10, 5],
  BUY_HEDGES_RANGE: {
    NIFTY: [17000, 21000],
    BANKNIFTY: [41000, 48000],
    FINNIFTY: [18000, 22000],
    MIDCPNIFTY: [7400, 8900],
    SENSEX: [60000, 70000],
  },
  STRADDLE_NO_STRIKES_EITHER_SIDE: 3,
};

const INSTRUMENT_CONFIG = {
  FINNIFTY: {
    instrumentToken: 257801,
    exchangeToken: 1007,
    finvToken: 26037,
    lotSize: 40,
    step: 50,
    freezeQty: 1800,
  },
  NIFTY: {
    instrumentToken: 256265,
    exchangeToken: 1001,
    finvToken: 26000,
    lotSize: 50,
    step: 50,
    freezeQty: 1800,
  },
  BANKNIFTY: {
    instrumentToken: 260105,
    exchangeToken: 1016,
    finvToken: 26009,
    lotSize: 15,
    step: 100,
    freezeQty: 900,
  },
  MIDCPNIFTY: {
    instrumentToken: 288009,
    exchangeToken: 1125,
    finvToken: 26074,
    lotSize: 75,
    step: 25,
    freezeQty: 4200,
  },
  SENSEX: {
    instrumentToken: 265,
    exchangeToken: 1,
    finvToken: 1,
    lotSize: 10,
    step: 100,
    freezeQty: 1000,
  },
};

const TurboComponent = () => {
  const [accounts, setAccounts] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [instrument, setInstrument] = useState(DEFAULT_SELECTS.instrument);
  const [expiry, setExpiry] = useState(DEFAULT_SELECTS.expiry);
  const [productType, setProductType] = useState(DEFAULT_SELECTS.productType);
  const [subscribedInstruments, setSubscribedInstruments] = useState([]);
  const [positions, setPositions] = useState({});
  const [ticks, setTicks] = useState({});
  const [pnl, setPnL] = useState(0);
  const [margin, setMargin] = useState({});

  const [execValue, setExecValue] = useState(DEFAULT_SELECTS.execValue);
  const [execIndivLegs, setExecIndivLegs] = useState(
    DEFAULT_SELECTS.execIndivLegs
  );
  const [showCurrentValueSell, setShowCurrentValueSell] = useState(false);
  const [showMove2, setShowMove2] = useState(false);
  const [straddleQty, setStraddleQty] = useState({});
  const [dayClosedPositions, setDayClosedPositions] = useState([]);
  const [dayOpenSellPositions, setDayOpenSellPositions] = useState([]);
  const [dayOpenBuyPositions, setDayOpenBuyPositions] = useState([]);
  const [dayOrders, setDayOrders] = useState([]);
  const [moveQty, setMoveQty] = useState({});
  const [TOKEN_MAPPING, setTokenMapping] = useState({});

  const [customRow, setCustomRow] = useState([]);
  const [userConfig, setUserConfig] = useState({});
  const [isExitPnLEnabled, setIsExitPnlEnabled] = useState(false);
  const [isPriceActionEnabled, setIsPriceActionEnabled] = useState(false);

  const [straddleList, setStraddleList] = useState([]);
  const [customLotList, setCustomLotList] = useState(
    DEFAULT_SELECTS.ADD_QTY_PRESELECT_LOTS
  );

  const instruments = [
    { value: "NIFTY", label: "NIFTY" },
    { value: "BANKNIFTY", label: "BANKNIFTY" },
    { value: "FINNIFTY", label: "FINNIFTY" },
    { value: "MIDCPNIFTY", label: "MIDCPNIFTY" },
    { value: "SENSEX", label: "SENSEX" },
  ];
  const expiries = [
    { value: "23N13", label: "13-NOV-2023" },
    { value: "23N14", label: "14-NOV-2023" },
    { value: "23N15", label: "15-NOV-2023" },
    { value: "23N16", label: "16-NOV-2023" },
    { value: "23N17", label: "17-NOV-2023" },
    { value: "23N10", label: "10-NOV-2023" },
  ];
  const productTypes = [
    { value: "NRML", label: "NRML" },
    { value: "MIS", label: "MIS" },
  ];

  useEffect(() => {
    getAccounts();
    getTokenMapping();
  }, []);

  useEffect(() => {
    getPnL();
  }, [ticks]);

  useEffect(() => {
    rebuildStraddleList();
  }, [subscribedInstruments]);

  useEffect(() => {
    renderConfigs();
    let userConfigExitPnlEnabled = userConfig?.pnl?.isEnabled;
    setIsExitPnlEnabled(userConfigExitPnlEnabled);
    let userConfigPriceActionEnabled = userConfig?.priceAction?.isEnabled;
    setIsPriceActionEnabled(userConfigPriceActionEnabled);
  }, [userConfig]);

  const getAccounts = () => {
    let userObj = storage.getLocalStorageItem("userData");
    let username = userObj?.username;
    AuthApi.get(`/api/secure/getUserAccounts?username=${username}`)
      .then((result) => {
        if (result?.status === 200 && result?.data) {
          let accountsData = result?.data?.data;
          let formattedAccountsData = accountsData.map((account) => {
            return {
              value: account.id,
              label: account.displayName,
              brokerid: account?.brokerid,
            };
          });
          setAccounts(formattedAccountsData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTokenMapping = () => {
    AuthApi.get(`/api/secure/getTokenMapping`)
      .then((result) => {
        if (result?.status === 200 && result?.data) {
          let tokenMap = result?.data?.data;
          setTokenMapping(tokenMap);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPnL = () => {
    let brokerid = getBrokerId();
    let netPositions = positions?.net; // TODO: ?.net or ?.day ???
    if (!netPositions) return;
    //TODO: Filter closed and open positions based on instrument selected
    let closedPositions = positionUtils.getClosedPositions(netPositions);
    let openPositions = positionUtils.getOpenPositions(netPositions);
    let closedPositionsPnL = positionUtils.getClosedPositionsPnL(
      closedPositions,
      brokerid
    );
    let openPositionsPnL = positionUtils.getOpenPositionsPnL(
      openPositions,
      ticks,
      brokerid
    );
    let totalPnL = closedPositionsPnL + openPositionsPnL;
    let token = INSTRUMENT_CONFIG[instrument]?.exchangeToken;
    let ltp = ticks[token] || "";
    let prefixMark = totalPnL >= 0 ? "💚" : "💔";
    document.title = `${prefixMark} [${totalPnL}] ${ltp}`;
    setPnL(totalPnL);
    setDayClosedPositions(closedPositions);
    let openSellPositions = openPositions.filter(
      (position) => position?.quantity < 0
    );
    let openBuyPositions = openPositions.filter(
      (position) => position?.quantity > 0
    );
    openSellPositions = userUtils.customPositionOrdering(
      openSellPositions,
      subscribedInstruments
    );
    openBuyPositions = userUtils.customPositionOrdering(
      openBuyPositions,
      subscribedInstruments
    );
    setDayOpenSellPositions(openSellPositions);
    setDayOpenBuyPositions(openBuyPositions);
  };

  const handleAccountsChange = (val) => {
    console.log(`handleAccountsChange => ${val}`);
    setAccountId(val);
  };

  const handleInstrumentChange = (val) => {
    console.log(`handleInstrumentChange => ${val}`);
    setInstrument(val);
  };

  const handleExpiryChange = (val) => {
    console.log(`handleExpiryChange => ${val}`);
    setExpiry(val);
  };

  const handleProductTypeChange = (val) => {
    console.log(`handleProductTypeChange => ${val}`);
    setProductType(val);
  };

  const initializeUser = () => {
    let turboKey = `${accountId}-${instrument}-${expiry}-${productType}`;
    console.log(`Turbo Key => ${turboKey}`);
    setVelociaxTurboKey(turboKey);
    AuthApi.post("/api/secure/initAccount", {
      accountId,
      instrument,
      expiry,
      productType,
    }).then((res) => {
      let result = res?.data;
      let data = result?.data;
      let quotes = data?.quotes;
      setSubscribedInstruments(quotes);
      // TODO: Client side enable/disable this logging below
      console.log(quotes);
      initializeSocketIO();
      refreshUI();
    });
  };

  const resetTurboAccount = () => {
    AuthApi.post("/api/secure/resetAccount").then((res) => {
      console.log("ResetTurboAccount ", res);
    });
  };

  const refreshUI = () => {
    getPositions();
    getOrders();
    getUserConfig();
    getMargins();
    rebuildStraddleList();
  };

  const getPositions = () => {
    AuthApi.post("/api/secure/getPositions", {})
      .then((res) => {
        let result = res?.data;
        let positionsResult = result?.data;
        setPositions(positionsResult);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOrders = () => {
    AuthApi.post("/api/secure/getOrders", {})
      .then((res) => {
        let result = res?.data;
        let ordersResult = result?.data;
        setDayOrders(ordersResult);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserConfig = () => {
    AuthApi.get("/api/secure/getUserConfig")
      .then((res) => {
        let result = res?.data;
        let userConfigData = result?.data;
        setUserConfig(userConfigData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMargins = () => {
    AuthApi.post("/api/secure/getUserMargins")
      .then((res) => {
        let result = res?.data;
        let marginData = result?.data;
        console.log(marginData);
        let formattedMargin = {
          used: marginData?.equity?.utilised?.debits,
          available: marginData?.equity?.net,
          cash: marginData?.equity?.available?.cash,
        };
        setMargin(formattedMargin);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveUserConfig = () => {
    let userConfigData = { ...userConfig };
    userConfigData.pnl.sl = parseFloat(userConfigData.pnl.sl);
    userConfigData.pnl.target = parseFloat(userConfigData.pnl.target);
    userConfigData.priceAction.ce = parseFloat(userConfigData.priceAction.ce);
    userConfigData.priceAction.pe = parseFloat(userConfigData.priceAction.pe);
    AuthApi.post("/api/secure/setUserConfig", userConfig)
      .then((res) => {
        let result = res?.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const rebuildStraddleList = () => {
    let indexInstrumentConfig = INSTRUMENT_CONFIG[instrument];
    let indexToken = indexInstrumentConfig?.exchangeToken;
    let indexLTP =
      ticks[indexToken] || subscribedInstruments[indexToken]?.last_price;
    let atmStrike = fnoUtils.getNearestStrikePrice(
      indexLTP,
      indexInstrumentConfig?.step
    );
    let allStrikes = fnoUtils.getStrikesEitherSide(
      atmStrike,
      indexInstrumentConfig?.step,
      DEFAULT_SELECTS.STRADDLE_NO_STRIKES_EITHER_SIDE
    );
    let exchangeTokens = Object.keys(subscribedInstruments) || [];
    exchangeTokens = exchangeTokens.filter((token) => {
      let currInstrument = subscribedInstruments[token];
      return (
        currInstrument?.name === instrument &&
        allStrikes.indexOf(currInstrument?.strike) > -1 &&
        (currInstrument?.instrumentType === "CE" ||
          currInstrument?.instrumentType === "PE")
      );
    });
    setStraddleList(exchangeTokens);
  };

  const makeAdjustment = (instrumentName, params) => {
    let data = {
      tradingsymbol: instrumentName,
      adjustment: params,
      instrument: instrument,
      expiry: expiry,
      productType: productType,
    };
    AuthApi.post("/api/secure/makeAdjustments", data).then((res) => {
      console.log("Make adjustments");
      console.log(res);
    });
  };

  const refreshPositionsOrders = () => {
    setTimeout(() => {
      refreshUI();
    }, 1000);
    setTimeout(() => {
      refreshUI();
    }, 5000);
  };

  const isBSE = (tradingSymbol) => {
    // TODO: Need to handle BANKEX if required in future
    return tradingSymbol.indexOf("SENSEX") === 0;
  };

  const placeTrade = (instrumentName, qty, buyOrSell) => {
    console.log(`Placing Trade ${instrumentName} - ${qty}`);
    let exchange = isBSE(instrumentName) ? "BFO" : "NFO";
    let data = {
      variety: "regular",
      exchange: exchange,
      tradingsymbol: instrumentName,
      transaction_type: buyOrSell,
      product: productType || "NRML",
      order_type: "MARKET",
      quantity: qty,
    };
    AuthApi.post("/api/secure/placeOrder", data).then((res) => {
      console.log("Placed Order");
      console.log(res);
      refreshPositionsOrders();
    });
  };

  const placeTrades = (legs) => {
    legs.forEach((leg) => {
      console.log(`Placing Multi Trade ${leg.tradingSymbol} - ${leg.qty}`);
      let exchange = isBSE(leg?.tradingSymbol) ? "BFO" : "NFO";
      let data = {
        variety: "regular",
        exchange: exchange,
        tradingsymbol: leg.tradingSymbol,
        transaction_type: leg.sellOrBuy,
        product: productType || "NRML",
        order_type: "MARKET",
        quantity: leg.qty,
      };
      AuthApi.post("/api/secure/placeOrder", data).then((res) => {
        console.log("Placed Order");
        console.log(res);
        refreshPositionsOrders();
      });
    });
  };

  const placeOrderStraddle = (strike, sellOrBuy, cePe) => {
    let legs = [];
    let cePeQtyVal = cePe;
    if (cePe?.length > 1) {
      cePeQtyVal = "CEPE";
    }
    let qty = straddleQty[strike][cePeQtyVal];
    cePe.forEach((peceType) => {
      let brokerid = getBrokerId();
      let tradingSymbol = buildSymbol(
        instrument,
        expiry,
        strike,
        peceType,
        brokerid
      );
      let obj = {
        tradingSymbol,
        sellOrBuy,
        qty,
      };
      legs.push(obj);
    });
    placeTrades(legs);
  };

  const placeCustomOrder = (idx, sellOrBuy, cePe) => {
    let selectedCustomRow = customRow[idx];
    let ceQty, peQty;
    let ceStrike, peStrike;
    if (cePe?.length > 1) {
      ceQty = selectedCustomRow?.combinedQty;
      peQty = selectedCustomRow?.combinedQty;
      ceStrike = selectedCustomRow?.ceStrike;
      peStrike = selectedCustomRow?.peStrike;
    } else if (cePe?.length === 1) {
      if (cePe[0] === "CE") {
        ceQty = selectedCustomRow?.ceQty;
        ceStrike = selectedCustomRow?.ceStrike;
      } else if (cePe[0] === "PE") {
        peQty = selectedCustomRow?.peQty;
        peStrike = selectedCustomRow?.peStrike;
      }
    }
    let legs = [];
    let brokerid = getBrokerId();
    cePe.forEach((optionType) => {
      let qty = optionType === "CE" ? ceQty : peQty;
      let strike = optionType === "CE" ? ceStrike : peStrike;
      let tradingSymbol = buildSymbol(
        instrument,
        expiry,
        strike,
        optionType,
        brokerid
      );
      let obj = {
        tradingSymbol,
        sellOrBuy,
        qty,
      };
      legs.push(obj);
    });
    placeTrades(legs);
  };

  const placeBuyMove = (
    fromStrike,
    toStrike,
    instrumentType,
    originalQty,
    adjustment
  ) => {
    let data = {
      instrument,
      fromStrike: parseInt(fromStrike),
      toStrike: parseInt(toStrike),
      expiry,
      instrumentType,
      originalQty,
      adjustment,
    };
    AuthApi.post("/api/secure/placeBuyMove", data).then((res) => {
      console.log("Placed Buy Move Order");
      console.log(res);
      refreshPositionsOrders();
    });
  };

  const formatTickData = (ticksData) => {
    let data = {};
    ticksData.forEach((instrument) => {
      data[instrument?.exchangeToken] = instrument?.last_price;
    });
    let newTicks = { ...ticks, ...data };
    setTicks(newTicks);
  };

  const initializeSocketIO = () => {
    socket = io.connect(process.env.REACT_APP_SOCKETIO_ENDPOINT);
    socket.emit("send_message", "sent from client...");
    socket?.on("TICK_DATA", (data) => {
      formatTickData(data);
    });
  };

  const updateTickConfig = (e) => {
    let userConfigData = { ...userConfig };
    userConfigData.ticks.global = e;
    setUserConfig(userConfigData);
    saveUserConfig();
  };

  const updateCustomLots = (e, idx) => {
    let tempLots = [...customLotList];
    tempLots[idx] = e.target.value;
    setCustomLotList(tempLots);
  };

  const renderCustomSellLots = () => {
    return customLotList.map((lots, idx) => {
      return (
        <Input
          className="mr-05rem"
          style={{
            width: 60,
          }}
          size="small"
          onChange={(e) => updateCustomLots(e, idx)}
          value={lots}
        />
      );
    });
  };

  const updatePriceActionTypeConfig = (e) => {
    let userConfigData = { ...userConfig };
    userConfigData.priceAction.adjustment.type = e;
    setUserConfig(userConfigData);
    saveUserConfig();
  };

  const updatePriceActionQtyConfig = (e) => {
    let userConfigData = { ...userConfig };
    userConfigData.priceAction.adjustment.qtyToAdjust.value = e;
    setUserConfig(userConfigData);
    saveUserConfig();
  };

  const updatePriceActionNoStrikesConfig = (e) => {
    let userConfigData = { ...userConfig };
    userConfigData.priceAction.adjustment.noStrikes = e;
    setUserConfig(userConfigData);
    saveUserConfig();
  };

  const renderConfigs = () => {
    let defaultMinTicks = userConfig?.ticks?.global || 1;
    let defaultPriceActionTypeConfig =
      userConfig?.priceAction?.adjustment?.type || "EXIT";
    let defaultPriceActionQtyConfig =
      userConfig?.priceAction?.adjustment?.qtyToAdjust?.value || 50;
    let defaultPriceActionNoStrikesConfig =
      userConfig?.priceAction?.adjustment?.noStrikes || 1;
    return (
      <div className="config-items-container">
        <div>
          <Space wrap>
            <Select
              style={{ width: 120 }}
              onChange={handleAccountsChange}
              options={accounts}
            />
            <Select
              style={{ width: 120 }}
              onChange={handleInstrumentChange}
              options={instruments}
              defaultValue={DEFAULT_SELECTS.instrument}
            />
            <Select
              style={{ width: 120 }}
              onChange={handleExpiryChange}
              options={expiries}
              defaultValue={DEFAULT_SELECTS.expiry}
            />
            <Select
              style={{ width: 100 }}
              onChange={handleProductTypeChange}
              options={productTypes}
              defaultValue={DEFAULT_SELECTS.productType}
            />
            <br />
          </Space>
        </div>
        <div className="pt-05rem" style={{ display: "flex" }}>
          <Button className="mr-05rem" onClick={initializeUser} type="primary">
            START
          </Button>
          <Button onClick={resetTurboAccount} type="primary">
            RESET
          </Button>
          <div
            className="pl-05rem"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span>Sell Lots</span>
            {renderCustomSellLots()}
          </div>
        </div>
        <Space className="config-items-container">
          <div className="pt-05rem">
            <span style={{ fontWeight: "bold" }}>Min Ticks: </span>
            <Select
              size="small"
              style={{ width: 120 }}
              options={getTicksValues()}
              value={defaultMinTicks}
              onChange={(e) => {
                updateTickConfig(e);
              }}
            />
          </div>
          <div className="pt-05rem">
            <div>
              <Checkbox
                className="pr-02rem"
                onChange={togglePriceAction}
                checked={isPriceActionEnabled}
              />
              <span className="pr-05rem" style={{ fontWeight: "bold" }}>
                Price Action{" "}
              </span>
              <span className="pr-02rem">CE: </span>
              <Input
                type="number"
                className="mr-05rem"
                style={{
                  width: 80,
                  border: isPriceActionEnabled ? "1px solid green" : "",
                }}
                size="small"
                onChange={(e) => updatePriceActionValue(e, "ce")}
                value={userConfig?.priceAction?.ce}
              />
              <span className="pr-02rem">PE: </span>
              <Input
                type="number"
                className="mr-05rem"
                style={{
                  width: 80,
                  border: isPriceActionEnabled ? "1px solid red" : "",
                }}
                size="small"
                onChange={(e) => updatePriceActionValue(e, "pe")}
                value={userConfig?.priceAction?.pe}
              />
              <Button size="small" onClick={saveUserConfig}>
                SAVE
              </Button>
            </div>
            <div>
              <Select
                size="small"
                style={{ width: 160 }}
                options={[
                  { value: "EXIT", label: "EXIT" },
                  {
                    value: "MOVE_OUT_THIS",
                    label: "MOVE OUT THIS",
                  },
                  { value: "MOVE_IN_OTHER", label: "MOVE IN OTHER" },
                ]}
                value={defaultPriceActionTypeConfig}
                onChange={(e) => {
                  updatePriceActionTypeConfig(e);
                }}
              />
              <span>% Qty</span>
              <Select
                size="small"
                style={{ width: 60 }}
                options={[
                  { value: 100, label: "1" },
                  { value: 50, label: "1/2" },
                  { value: 25, label: "1/4" },
                ]}
                value={defaultPriceActionQtyConfig}
                onChange={(e) => {
                  updatePriceActionQtyConfig(e);
                }}
              />
              <span># Strikes</span>
              <Select
                size="small"
                style={{ width: 50 }}
                options={[
                  { value: 1, label: 1 },
                  { value: 2, label: 2 },
                  { value: 3, label: 3 },
                ]}
                value={defaultPriceActionNoStrikesConfig}
                onChange={(e) => {
                  updatePriceActionNoStrikesConfig(e);
                }}
              />
            </div>
          </div>
        </Space>
      </div>
    );
  };

  const changeExecValue = (e) => {
    setExecValue(e.target.value);
  };

  const toggleExecIndivLegs = (e) => {
    setExecIndivLegs(e?.target?.checked);
  };

  const toggleShowCurrentValueSell = (e) => {
    setShowCurrentValueSell(e?.target?.checked);
  };

  const toggleExitPnlEnabled = (e) => {
    let currValue = e?.target?.checked;
    let userConfigData = { ...userConfig };
    userConfigData.pnl.isEnabled = currValue;
    setIsExitPnlEnabled(currValue);
    saveUserConfig();
  };

  const togglePriceAction = (e) => {
    let currValue = e?.target?.checked;
    let userConfigData = { ...userConfig };
    userConfigData.priceAction.isEnabled = currValue;
    setIsPriceActionEnabled(currValue);
    saveUserConfig();
  };

  const toggleShowMove2 = (e) => {
    setShowMove2(e?.target?.checked);
  };

  const getQtyMultiples = () => {
    let result = [];
    let indexInstrumentConfig = INSTRUMENT_CONFIG[instrument];
    let lotSize = indexInstrumentConfig?.lotSize;
    let freezeQty = indexInstrumentConfig?.freezeQty;
    let start = lotSize;
    let end = freezeQty;
    for (let i = start; i <= end; i = i + lotSize) {
      let obj = {
        value: i,
        label: i,
      };
      result.push(obj);
    }
    return result;
  };

  const getTicksValues = () => {
    let minTicks = 1;
    let maxTicks = 10;
    let result = [];
    for (let i = minTicks; i < maxTicks; i++) {
      let obj = {
        value: i,
        label: i === 1 ? `${i} (Instant)` : i,
      };
      result.push(obj);
    }
    return result;
  };

  const changeStraddleQty = (qty, strike, cePe) => {
    let newStraddleQty = { ...straddleQty };
    newStraddleQty[strike] = newStraddleQty[strike] || {};
    newStraddleQty[strike][cePe] = qty;
    setStraddleQty(newStraddleQty);
  };

  const buildStraddleDataSource = () => {
    if (!straddleList?.length) {
      return [];
    }
    let uniqueStrikes = [];
    straddleList.forEach((token) => {
      let strike = parseInt(subscribedInstruments[token]?.strike);
      if (uniqueStrikes.indexOf(strike) === -1) {
        // TODO: Add only limited strikes here based on ATM strike
        uniqueStrikes.push(strike);
      }
    });
    uniqueStrikes = uniqueStrikes.sort();
    let dataSource = [];
    uniqueStrikes.forEach((strike, idx) => {
      let ceToken = fnoUtils.getTokenIdFromStrikePECE(
        instrument,
        subscribedInstruments,
        strike,
        "CE"
      );
      let peToken = fnoUtils.getTokenIdFromStrikePECE(
        instrument,
        subscribedInstruments,
        strike,
        "PE"
      );
      let ceOpen = subscribedInstruments[ceToken]?.ohlc?.open || 0;
      let peOpen = subscribedInstruments[peToken]?.ohlc?.open || 0;
      let ceClose = subscribedInstruments[ceToken]?.ohlc?.close || 0;
      let peClose = subscribedInstruments[peToken]?.ohlc?.close || 0;
      let obj = {
        key: idx,
        ce: strike,
        pe: strike,
        ceLtp: (
          <span style={{ fontWeight: "bold" }}>
            {numUtils.getToFixedNumber(ticks[ceToken], 2)}
          </span>
        ),
        peLtp: (
          <span style={{ fontWeight: "bold" }}>
            {numUtils.getToFixedNumber(ticks[peToken], 2)}
          </span>
        ),
        now: (
          <span style={{ fontWeight: "bold" }}>
            {numUtils.getToFixedNumber(ticks[ceToken] + ticks[peToken], 2)}
          </span>
        ),
        open: numUtils.getToFixedNumber(ceOpen + peOpen, 2),
        prev: numUtils.getToFixedNumber(ceClose + peClose, 2),
        ceSellBuy: (
          <>
            <Select
              showSearch
              style={{ width: 100 }}
              options={getQtyMultiples()}
              onChange={(e) => {
                changeStraddleQty(e, strike, "CE");
              }}
            />
            <Space>
              <Button
                onClick={() => {
                  placeOrderStraddle(strike, "SELL", ["CE"]);
                }}
              >
                S
              </Button>
              <Button
                onClick={() => {
                  placeOrderStraddle(strike, "BUY", ["CE"]);
                }}
              >
                B
              </Button>
            </Space>
          </>
        ),
        peSellBuy: (
          <>
            <Select
              showSearch
              style={{ width: 100 }}
              options={getQtyMultiples()}
              onChange={(e) => {
                changeStraddleQty(e, strike, "PE");
              }}
            />
            <Space>
              <Button
                onClick={() => {
                  placeOrderStraddle(strike, "SELL", ["PE"]);
                }}
              >
                S
              </Button>
              <Button
                onClick={() => {
                  placeOrderStraddle(strike, "BUY", ["PE"]);
                }}
              >
                B
              </Button>
            </Space>
          </>
        ),
        combinedSellBuy: (
          <>
            <Select
              showSearch
              style={{ width: 100 }}
              options={getQtyMultiples()}
              onChange={(e) => {
                changeStraddleQty(e, strike, "CEPE");
              }}
            />
            <Space>
              <Button
                onClick={() => {
                  placeOrderStraddle(strike, "SELL", ["CE", "PE"]);
                }}
              >
                S
              </Button>
              <Button
                onClick={() => {
                  placeOrderStraddle(strike, "BUY", ["CE", "PE"]);
                }}
              >
                B
              </Button>
            </Space>
          </>
        ),
      };
      dataSource.push(obj);
    });
    return dataSource;
  };

  const changeCustomRowCell = (qty, idx, key) => {
    let tempCustomRow = [];
    for (let i = 0; i < customRow.length; i++) {
      if (i === idx) {
        customRow[i][key] = qty;
      }
      tempCustomRow[i] = customRow[i];
    }
    setCustomRow(tempCustomRow);
  };

  const buildCustomListDataSource = () => {
    let dataSource = [];
    // TODO: Build this dynamically from selected instrument
    let step = INSTRUMENT_CONFIG[instrument]?.step || 100;
    let strikesArr = [];
    let buyHedgeRange = DEFAULT_SELECTS.BUY_HEDGES_RANGE[instrument];
    for (let i = buyHedgeRange[0]; i <= buyHedgeRange[1]; i = i + step) {
      let obj = {
        value: i,
        label: i,
      };
      strikesArr.push(obj);
    }
    customRow?.forEach((row, idx) => {
      let ceStrike = row?.ceStrike;
      let peStrike = row?.peStrike;
      let ceQty = row?.ceQty;
      let peQty = row?.peQty;
      let combinedQty = row?.combinedQty;
      let ceToken,
        peToken,
        ceLtp,
        peLtp,
        ceOpen,
        ceClose,
        peOpen,
        peClose,
        prev,
        open,
        now;
      if (ceStrike) {
        ceToken = fnoUtils.getTokenIdFromStrikePECE(
          instrument,
          subscribedInstruments,
          ceStrike,
          "CE"
        );
        ceOpen = subscribedInstruments[ceToken]?.ohlc?.open || 0;
        ceClose = subscribedInstruments[ceToken]?.ohlc?.close || 0;
        ceLtp = numUtils.getToFixedNumber(ticks[ceToken], 2);
      }
      if (peStrike) {
        peToken = fnoUtils.getTokenIdFromStrikePECE(
          instrument,
          subscribedInstruments,
          peStrike,
          "PE"
        );
        peOpen = subscribedInstruments[peToken]?.ohlc?.open || 0;
        peClose = subscribedInstruments[peToken]?.ohlc?.close || 0;
        peLtp = numUtils.getToFixedNumber(ticks[peToken], 2);
      }
      if (ceStrike && peStrike) {
        open = numUtils.getToFixedNumber(ceOpen + peOpen, 2);
        prev = numUtils.getToFixedNumber(ceClose + peClose, 2);
        now = numUtils.getToFixedNumber(ceLtp + peLtp, 2);
      }
      let obj = {
        ce: (
          <Select
            showSearch
            size="small"
            style={{ width: 80 }}
            options={strikesArr}
            defaultValue={ceStrike}
            onChange={(e) => {
              changeCustomRowCell(e, idx, "ceStrike");
            }}
          ></Select>
        ),
        ceSellBuy: (
          <>
            <Select
              showSearch
              size="small"
              style={{ width: 80 }}
              options={getQtyMultiples()}
              defaultValue={ceQty}
              onChange={(e) => {
                changeCustomRowCell(e, idx, "ceQty");
              }}
            ></Select>
            <Space>
              <Button
                onClick={() => {
                  placeCustomOrder(idx, "SELL", ["CE"]);
                }}
              >
                S
              </Button>
              <Button
                onClick={() => {
                  placeCustomOrder(idx, "BUY", ["CE"]);
                }}
              >
                B
              </Button>
            </Space>
          </>
        ),
        pe: (
          <Select
            showSearch
            size="small"
            style={{ width: 80 }}
            options={strikesArr}
            defaultValue={peStrike}
            onChange={(e) => {
              changeCustomRowCell(e, idx, "peStrike");
            }}
          ></Select>
        ),
        peSellBuy: (
          <>
            <Select
              showSearch
              size="small"
              style={{ width: 80 }}
              options={getQtyMultiples()}
              defaultValue={peQty}
              onChange={(e) => {
                changeCustomRowCell(e, idx, "peQty");
              }}
            ></Select>
            <Space>
              <Button
                onClick={() => {
                  placeCustomOrder(idx, "SELL", ["PE"]);
                }}
              >
                S
              </Button>
              <Button
                onClick={() => {
                  placeCustomOrder(idx, "BUY", ["PE"]);
                }}
              >
                B
              </Button>
            </Space>
          </>
        ),
        combinedSellBuy: (
          <>
            {" "}
            <Select
              showSearch
              size="small"
              style={{ width: 80 }}
              options={getQtyMultiples()}
              defaultValue={combinedQty}
              onChange={(e) => {
                changeCustomRowCell(e, idx, "combinedQty");
              }}
            ></Select>
            <Space>
              <Button
                onClick={() => {
                  placeCustomOrder(idx, "SELL", ["CE", "PE"]);
                }}
              >
                S
              </Button>
              <Button
                onClick={() => {
                  placeCustomOrder(idx, "BUY", ["CE", "PE"]);
                }}
              >
                B
              </Button>
            </Space>
          </>
        ),
        ceLtp: <span style={{ fontWeight: "bold" }}>{ceLtp}</span>,
        peLtp: <span style={{ fontWeight: "bold" }}>{peLtp}</span>,
        open: open,
        prev: prev,
        now: <span style={{ fontWeight: "bold" }}>{now}</span>,
      };
      dataSource.push(obj);
    });
    return dataSource;
  };

  const renderStraddleExecutorTable = () => {
    let isShowIndividualLegs = execIndivLegs;
    let dataSource = buildStraddleDataSource();
    let columns = [
      { title: "CE", dataIndex: "ce", key: "ce", width: 60 },
      { title: "LTP", dataIndex: "ceLtp", key: "ceLtp", width: 70 },
      { title: "PE", dataIndex: "pe", key: "pe", width: 60 },
      { title: "LTP", dataIndex: "peLtp", key: "peLtp", width: 70 },
      { title: "Prev", dataIndex: "prev", key: "prev", width: 70 },
      { title: "Open", dataIndex: "open", key: "open", width: 70 },
      { title: "Now", dataIndex: "now", key: "now", width: 70 },
      {
        title: "Combined Sell/Buy",
        dataIndex: "combinedSellBuy",
        key: "combinedSellBuy",
      },
    ];
    if (isShowIndividualLegs) {
      columns.splice(2, 0, {
        title: "Sell/Buy",
        dataIndex: "ceSellBuy",
        key: "ceSellBuy",
      });
      columns.splice(5, 0, {
        title: "Sell/Buy",
        dataIndex: "peSellBuy",
        key: "peSellBuy",
      });
    }
    return (
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        pagination={{ hideOnSinglePage: true }}
        scroll={{ y: "40vh" }}
        rowClassName={setStraddleRow}
      />
    );
  };

  const renderStrangleExecutorTable = () => {
    return <h3>STRANGLE</h3>;
  };

  const addCustomRow = () => {
    let currCustomRow = customRow;
    currCustomRow.push({});
    setCustomRow(currCustomRow);
  };

  const removeCustomRow = () => {
    let currCustomRow = customRow;
    if (currCustomRow.length > 0) {
      currCustomRow.pop();
    }
    setCustomRow(currCustomRow);
  };

  const renderCustomExecutorTable = () => {
    let isShowIndividualLegs = execIndivLegs;
    let dataSource = buildCustomListDataSource();
    let columns = [
      { title: "CE", dataIndex: "ce", key: "ce", width: 90 },
      { title: "LTP", dataIndex: "ceLtp", key: "ceLtp", width: 70 },
      { title: "PE", dataIndex: "pe", key: "pe", width: 90 },
      { title: "LTP", dataIndex: "peLtp", key: "peLtp", width: 70 },
      { title: "Prev", dataIndex: "prev", key: "prev", width: 70 },
      { title: "Open", dataIndex: "open", key: "open", width: 70 },
      { title: "Now", dataIndex: "now", key: "now", width: 70 },
      {
        title: "Combined Sell/Buy",
        dataIndex: "combinedSellBuy",
        key: "combinedSellBuy",
      },
    ];
    if (isShowIndividualLegs) {
      columns.splice(2, 0, {
        title: "Sell/Buy",
        dataIndex: "ceSellBuy",
        key: "ceSellBuy",
      });
      columns.splice(5, 0, {
        title: "Sell/Buy",
        dataIndex: "peSellBuy",
        key: "peSellBuy",
      });
    }
    return (
      <>
        <Space
          style={{
            marginBottom: 16,
          }}
        >
          <Button onClick={addCustomRow}>Add New Row</Button>
          <Button onClick={removeCustomRow}>Remove Last Row</Button>
        </Space>
        <Table
          dataSource={dataSource}
          columns={columns}
          size="small"
          pagination={{ hideOnSinglePage: true }}
          scroll={{ y: "40vh" }}
        />
      </>
    );
  };

  const renderExecutorTable = () => {
    let selectedType = execValue;
    if (selectedType === "exec-straddle") {
      return renderStraddleExecutorTable();
    } else if (selectedType === "exec-strangle") {
      return renderStrangleExecutorTable();
    } else if (selectedType === "exec-custom") {
      return renderCustomExecutorTable();
    }
  };

  const renderExecutor = () => {
    return (
      <>
        <Row>
          <Col>
            <Radio.Group onChange={changeExecValue} value={execValue}>
              <Radio value="exec-straddle">Straddle</Radio>
              <Radio value="exec-strangle">Strangle</Radio>
              <Radio value="exec-custom">Custom</Radio>
            </Radio.Group>
            <Checkbox onChange={toggleExecIndivLegs} checked={execIndivLegs}>
              Individual Legs
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col>{renderExecutorTable()}</Col>
        </Row>
      </>
    );
  };

  const renderMargin = () => {
    let totalMargin = margin?.used + margin?.available;
    let percentUsed = totalMargin ? (margin?.used / totalMargin) * 100 : 0;
    percentUsed = numUtils.getToFixedNumber(percentUsed, 2);
    let availableMargin = margin?.available;
    let availableMarginFormatted = availableMargin;
    if (availableMarginFormatted > 100000) {
      if (availableMarginFormatted > 10000000) {
        let marginInCrores = numUtils.getToFixedNumber(
          availableMarginFormatted / 10000000,
          2
        );
        availableMarginFormatted = `${marginInCrores} Cr`;
      } else {
        let marginInLakhs = numUtils.getToFixedNumber(
          availableMarginFormatted / 100000,
          2
        );
        availableMarginFormatted = `${marginInLakhs} L`;
      }
    }
    return (
      <div style={{ width: 200 }}>
        <Progress
          percent={percentUsed}
          size="small"
          strokeColor="orangered"
          trailColor="lightgreen"
        />{" "}
        <span>Margin Available: {availableMarginFormatted}</span>
      </div>
    );
  };

  const renderIndex = () => {
    let token = INSTRUMENT_CONFIG[instrument]?.exchangeToken;
    let ltp = ticks[token];
    let prevClose = subscribedInstruments[token]?.ohlc?.close;
    let diff = numUtils.getToFixedNumber(ltp - prevClose, 2);
    let percent = numUtils.getToFixedNumber((diff / prevClose) * 100, 2);
    return (
      <div className="index-margin-container">
        <div>
          <span>
            {instrument}{" "}
            <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
              {ltp}{" "}
            </span>{" "}
            (
            <span style={diff < 0 ? { color: "red" } : { color: "green" }}>
              {diff} / {percent}%
            </span>
            )
          </span>
        </div>
      </div>
    );
  };

  const renderRefresh = () => {
    return (
      <div className="index-margin-container">
        <div>
          <ReloadOutlined
            onClick={refreshUI}
            style={{ color: "blue", fontSize: "1.5rem", cursor: "pointer" }}
          />
        </div>

        <div>{renderMargin()}</div>
      </div>
    );
  };

  const updatePnLExitValue = (e, type) => {
    let newValue = e.target.value;
    let userConfigData = { ...userConfig };
    userConfig.pnl[type] = newValue;
    setUserConfig(userConfigData);
  };

  const updatePriceActionValue = (e, type) => {
    let newValue = e.target.value;
    let userConfigData = { ...userConfig };
    userConfig.priceAction[type] = newValue;
    setUserConfig(userConfigData);
  };

  const renderPnL = () => {
    return (
      <span>
        Today's PnL:{" "}
        <span
          style={
            pnl < 0
              ? { color: "red", fontSize: "1.5rem" }
              : { color: "green", fontSize: "1.5rem", fontWeight: "bold" }
          }
        >
          {pnl}
        </span>
        <br />
        <div className="pt-05rem">
          <Checkbox
            style={{ paddingLeft: "2rem" }}
            onChange={toggleExitPnlEnabled}
            checked={isExitPnLEnabled}
          >
            Exit PnL{" "}
          </Checkbox>
          <span>Target: </span>
          <Input
            className="mr-05rem"
            style={{
              width: 80,
              border: isExitPnLEnabled ? "1px solid green" : "",
            }}
            size="small"
            onChange={(e) => updatePnLExitValue(e, "target")}
            value={userConfig?.pnl?.target}
          />
          <span>SL: </span>
          <Input
            className="mr-05rem"
            style={{
              width: 80,
              border: isExitPnLEnabled ? "1px solid red" : "",
            }}
            size="small"
            onChange={(e) => updatePnLExitValue(e, "sl")}
            value={userConfig?.pnl?.sl}
          />

          <Button size="small" onClick={saveUserConfig}>
            SAVE
          </Button>
        </div>
      </span>
    );
  };

  const renderMoveRowCell = (strikeToMove, ltp, position, params) => {
    let tradingSymbol = position?.tradingsymbol;

    return (
      <Row>
        {strikeToMove && (
          <Col span={12}>
            <Row>
              <Col style={{ fontSize: "0.75rem" }} span={14}>
                {strikeToMove}
              </Col>
              <Col style={{ fontSize: "0.75rem" }} span={10}>
                ({ltp})
              </Col>
            </Row>
          </Col>
        )}
        <Col span={strikeToMove ? 12 : 24}>
          <Row>
            <Col span={8} style={{ paddingLeft: "5px" }}>
              <Button
                style={{ fontSize: "0.6rem" }}
                size="small"
                onClick={() => {
                  makeAdjustment(tradingSymbol, {
                    ...params,
                    ...{ qtyToAdjust: { type: "PERCENT", value: 25 } },
                  });
                }}
              >
                1/4
              </Button>
            </Col>
            <Col span={8} style={{ paddingLeft: "5px" }}>
              <Button
                style={{ fontSize: "0.6rem" }}
                size="small"
                onClick={() => {
                  makeAdjustment(tradingSymbol, {
                    ...params,
                    ...{ qtyToAdjust: { type: "PERCENT", value: 50 } },
                  });
                }}
              >
                1/2
              </Button>
            </Col>
            <Col span={8} style={{ paddingLeft: "5px" }}>
              <Button
                style={{ fontSize: "0.6rem" }}
                size="small"
                onClick={() => {
                  makeAdjustment(tradingSymbol, {
                    ...params,
                    ...{ qtyToAdjust: { type: "PERCENT", value: 100 } },
                  });
                }}
              >
                1
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const pickTradingSymbolFromInstrument = (currStrikeInstrument, brokerId) => {
    if (brokerId === 2) {
      return currStrikeInstrument?.tradingSymbol_finvasia;
    } else {
      return currStrikeInstrument?.tradingSymbol_zerodha;
    }
  };

  const renderAddQty = (currStrikeInstrument, transactionType) => {
    let brokerId = getBrokerId();
    let tradingSymbol = pickTradingSymbolFromInstrument(
      currStrikeInstrument,
      brokerId
    );
    let indexInstrumentConfig = INSTRUMENT_CONFIG[instrument];
    let lotSize = indexInstrumentConfig?.lotSize;
    let preselectedQty = customLotList.map((lots) => {
      return lots * lotSize;
    });
    return (
      <Row>
        {preselectedQty.map((qty) => {
          return (
            <Col span={8} style={{ paddingLeft: "5px" }}>
              <Button
                style={{ fontSize: "0.6rem" }}
                size="small"
                onClick={() => {
                  placeTrade(tradingSymbol, qty, transactionType);
                }}
              >
                {qty}
              </Button>
            </Col>
          );
        })}
      </Row>
    );
  };

  const changeMoveQty = (qty, fromStrike, cePe) => {
    let newMoveQty = { ...moveQty };
    newMoveQty[fromStrike] = newMoveQty[fromStrike] || {};
    newMoveQty[fromStrike][cePe] = qty;
    setMoveQty(newMoveQty);
  };

  const moveBuyPosition = (
    fromStrike,
    instrumentType,
    originalQty,
    adjustment
  ) => {
    let fromStrikeObj = moveQty[fromStrike];
    let toStrike = fromStrikeObj[instrumentType];
    placeBuyMove(fromStrike, toStrike, instrumentType, originalQty, adjustment);
  };

  const getBrokerId = () => {
    let accountObj = accounts?.find((account) => {
      return account.value === accountId;
    });
    return accountObj?.brokerid;
  };

  const buildSymbol = (
    instrument,
    expiry,
    strike,
    instrumentType,
    brokerId
  ) => {
    if (brokerId === 1) {
      return `${instrument}${expiry}${strike}${instrumentType}`;
    } else if (brokerId === 2) {
      let customExpiry;
      // TODO: CRITICAL! Fix dynamic expiry calcluation for non zerodha brokers
      if (expiry === "23718" && instrument === "FINNIFTY") {
        customExpiry = "18JUL23";
      }
      if (expiry === "23719" && instrument === "MIDCPNIFTY") {
        customExpiry = "19JUL23";
      }
      if (
        expiry === "23720" &&
        (instrument === "NIFTY" || instrument === "BANKNIFTY")
      ) {
        customExpiry = "20JUL23";
      }
      if (expiry === "23721" && instrument === "SENSEX") {
        customExpiry = "21JUL23";
      }
      if (expiry === "23714" && instrument === "SENSEX") {
        customExpiry = "14JUL23";
      }
      let instrumentTypeLetter = instrumentType[0];
      return `${instrument}${customExpiry}${instrumentTypeLetter}${strike}`;
    }
  };

  const symbolToExchToken = (tradingSymbol, brokerid) => {
    if (brokerid === 1) {
      return TOKEN_MAPPING?.zerodhaTradingSymbolToExch[tradingSymbol];
    } else if (brokerid == 2) {
      return TOKEN_MAPPING?.finvasiaTradingSymbolToExch[tradingSymbol];
    }
  };

  const renderMovePosition = (instrumentType, fromStrike, originalQty) => {
    let step = INSTRUMENT_CONFIG[instrument]?.step || 100;
    let buyHedgeRange = DEFAULT_SELECTS.BUY_HEDGES_RANGE[instrument];
    let strikesArr = [];
    for (let i = buyHedgeRange[0]; i <= buyHedgeRange[1]; i = i + step) {
      let obj = {
        value: i,
        label: i,
      };
      strikesArr.push(obj);
    }
    let toStrikeObj = moveQty[fromStrike];
    let toStrike = toStrikeObj && toStrikeObj[instrumentType];
    let toSymbol;
    let brokerid = getBrokerId();
    if (toStrike) {
      toSymbol = buildSymbol(
        instrument,
        expiry,
        toStrike,
        instrumentType,
        brokerid
      );
    }
    let toStrikeToken = symbolToExchToken(toSymbol, brokerid); //TODO: Get dynamically token from symbol
    let ltp;
    if (toStrikeToken) {
      ltp = ticks[toStrikeToken];
    }
    return (
      <>
        <Select
          showSearch
          size="small"
          style={{ width: 80 }}
          options={strikesArr}
          onChange={(e) => {
            changeMoveQty(e, fromStrike, instrumentType);
          }}
        ></Select>
        <span>({ltp})</span>
        <Button
          style={{ fontSize: "0.6rem" }}
          size="small"
          onClick={() => {
            moveBuyPosition(fromStrike, instrumentType, originalQty, {
              qtyToAdjust: {
                type: "PERCENT",
                value: 50,
              },
            });
          }}
        >
          1/2
        </Button>
        <Button
          style={{ fontSize: "0.6rem" }}
          size="small"
          onClick={() => {
            moveBuyPosition(fromStrike, instrumentType, originalQty, {
              qtyToAdjust: {
                type: "PERCENT",
                value: 100,
              },
            });
          }}
        >
          1
        </Button>
      </>
    );
  };

  const buildSellPositionsDatasource = () => {
    let dataSource = [];
    dayOpenSellPositions.forEach((position) => {
      let currStrikeInstrument = subscribedInstruments[position?.exchangeToken];
      if (currStrikeInstrument?.name === instrument) {
        let cepe = currStrikeInstrument?.instrumentType;
        let strike = parseInt(currStrikeInstrument?.strike);
        let step = INSTRUMENT_CONFIG[instrument]?.step;
        let strikeMoveAway = cepe === "CE" ? strike + step : strike - step;
        let strikeMoveClose = cepe === "CE" ? strike - step : strike + step;
        let tokenMoveAway = Object.keys(subscribedInstruments)?.filter(
          (token) => {
            let ins = subscribedInstruments[token];
            return (
              ins?.instrumentType === cepe &&
              parseInt(ins?.strike) === strikeMoveAway &&
              ins?.name === instrument
            );
          }
        );
        let tokenMoveClose = Object.keys(subscribedInstruments)?.filter(
          (token) => {
            let ins = subscribedInstruments[token];
            return (
              ins?.instrumentType === cepe &&
              parseInt(ins?.strike) === strikeMoveClose &&
              ins?.name === instrument
            );
          }
        );
        let qty = position?.quantity;
        let obj = {
          strike: currStrikeInstrument?.strike,
          cepe: cepe,
          qty: (
            <span
              style={
                qty < 0
                  ? { color: "red", fontWeight: "bold" }
                  : { color: "blue", fontWeight: "bold" }
              }
            >
              {qty}
            </span>
          ),
          avg: numUtils.getToFixedNumber(position?.average_price, 2),
          ltp: (
            <span style={{ fontWeight: "bold" }}>
              {ticks[position?.exchangeToken]}
            </span>
          ),
          currentValue: (
            <span className="fs-075rem">
              {numUtils.getToFixedNumber(
                Math.abs(ticks[position?.exchangeToken] * qty),
                2
              )}
            </span>
          ),
          add: renderAddQty(currStrikeInstrument, "SELL"),
          moveAway: renderMoveRowCell(
            strikeMoveAway,
            ticks[tokenMoveAway],
            position,
            {
              type: "MOVE_OUT",
              noStrikes: 1,
            }
          ),
          moveClose: renderMoveRowCell(
            strikeMoveClose,
            ticks[tokenMoveClose],
            position,
            {
              type: "MOVE_IN",
              noStrikes: 1,
            }
          ),
          exit: renderMoveRowCell(null, null, position, {
            type: "EXIT",
          }),
        };
        dataSource.push(obj);
      }
    });
    return dataSource;
  };

  const setStraddleRow = (record, index) => {
    let className = "";
    // TODO: Extract below into getAtmStrike() and use inside executorTable also
    let indexInstrumentConfig = INSTRUMENT_CONFIG[instrument];
    let indexToken = indexInstrumentConfig?.exchangeToken;
    let indexLTP = ticks[indexToken];
    let atmStrike = fnoUtils.getNearestStrikePrice(
      indexLTP,
      indexInstrumentConfig?.step
    );
    if (record?.ce === atmStrike) {
      className = "lightyellow-row";
    }
    return className;
  };

  const setStylesCePeRow = (record, index) => {
    let className = "";
    if (record?.cepe === "PE") {
      className = "orange-row";
    } else if (record?.cepe === "CE") {
      className = "green-row";
    }

    return className;
  };

  const renderSellPositions = () => {
    let dataSource = buildSellPositionsDatasource();
    let columns = [
      { title: "Strike", dataIndex: "strike", key: "strike", width: 60 },
      { title: "C/P", dataIndex: "cepe", key: "cepe", width: 60 },
      { title: "Qty", dataIndex: "qty", key: "qty", width: 80 },
      { title: "Avg", dataIndex: "avg", key: "avg", width: 70 },
      { title: "LTP", dataIndex: "ltp", key: "ltp", width: 70 },
      { title: "Add", dataIndex: "add", key: "add" },
      { title: "Move Away", dataIndex: "moveAway", key: "moveAway" },
      { title: "Move Closer", dataIndex: "moveClose", key: "moveClose" },
      { title: "Exit", dataIndex: "exit", key: "exit" },
    ];
    if (showCurrentValueSell) {
      columns.splice(5, 0, {
        title: "Value",
        dataIndex: "currentValue",
        key: "currentValue",
        width: 60,
      });
    }
    return (
      <div style={{ paddingRight: "1rem" }}>
        <div>
          <h3>
            Sell Positions{" "}
            <Checkbox
              style={{ paddingLeft: "2rem" }}
              onChange={toggleShowCurrentValueSell}
              checked={showCurrentValueSell}
            >
              Current Value
            </Checkbox>
            <Checkbox
              disabled
              style={{ paddingLeft: "2rem" }}
              onChange={toggleShowMove2}
              checked={showMove2}
            >
              Move 2
            </Checkbox>
          </h3>
        </div>
        <Table
          dataSource={dataSource}
          columns={columns}
          size="small"
          pagination={{ hideOnSinglePage: true }}
          scroll={{ y: "40vh" }}
          rowClassName={setStylesCePeRow}
        />
      </div>
    );
  };

  const buildBuyPositionsDatasource = () => {
    let dataSource = [];
    dayOpenBuyPositions.forEach((position) => {
      let currStrikeInstrument = subscribedInstruments[position?.exchangeToken];
      if (currStrikeInstrument?.name === instrument) {
        let qty = position?.quantity;
        let obj = {
          strike: currStrikeInstrument?.strike,
          cepe: currStrikeInstrument?.instrumentType,
          qty: (
            <span style={qty < 0 ? { color: "red" } : { color: "blue" }}>
              {qty}
            </span>
          ),
          avg: numUtils.getToFixedNumber(position?.average_price, 2),
          ltp: (
            <span style={{ fontWeight: "bold" }}>
              {ticks[position?.exchangeToken]}
            </span>
          ),
          add: renderAddQty(currStrikeInstrument, "BUY"),
          move: renderMovePosition(
            currStrikeInstrument?.instrumentType,
            currStrikeInstrument?.strike,
            qty
          ),
          exit: renderMoveRowCell(null, null, position, {
            type: "EXIT",
          }),
        };
        dataSource.push(obj);
      }
    });
    return dataSource;
  };

  const renderBuyPositions = () => {
    let dataSource = buildBuyPositionsDatasource();
    let columns = [
      { title: "Strike", dataIndex: "strike", key: "strike", width: 60 },
      { title: "C/P", dataIndex: "cepe", key: "cepe", width: 60 },
      { title: "Qty", dataIndex: "qty", key: "qty", width: 60 },
      { title: "Avg", dataIndex: "avg", key: "avg", width: 60 },
      { title: "LTP", dataIndex: "ltp", key: "ltp", width: 60 },
      { title: "Add", dataIndex: "add", key: "add" },
      { title: "Move", dataIndex: "move", key: "move" },
      { title: "Exit", dataIndex: "exit", key: "exit" },
    ];
    return (
      <div style={{ paddingRight: "1rem" }}>
        <h3>Buy Positions</h3>
        <Table
          dataSource={dataSource}
          columns={columns}
          size="small"
          pagination={{ hideOnSinglePage: true }}
          scroll={{ y: "40vh" }}
          rowClassName={setStylesCePeRow}
        />
      </div>
    );
  };

  const buildOrdersDatasource = () => {
    if (!dayOrders || !dayOrders?.length) {
      return [];
    }
    let dataSource = [];
    let brokerid = getBrokerId();
    for (let i = dayOrders.length - 1; i >= 0; i--) {
      let order = dayOrders[i];
      let currStrikeInstrument = subscribedInstruments[order?.exchangeToken];
      if (currStrikeInstrument?.name === instrument) {
        let transactionType = order?.transaction_type;
        let obj = {
          key: i,
          time: numUtils.getTime(order?.order_timestamp, brokerid),
          buySell: (
            <span
              style={
                transactionType === "SELL"
                  ? { color: "red" }
                  : { color: "green" }
              }
            >
              {transactionType}
            </span>
          ),
          strike: currStrikeInstrument?.strike,
          cepe: currStrikeInstrument?.instrumentType,
          qty: (
            <span
              style={
                transactionType === "SELL"
                  ? { color: "red" }
                  : { color: "green" }
              }
            >
              {order?.quantity}
            </span>
          ),
          avg: numUtils.getToFixedNumber(order?.average_price, 2),
          status: <span style={{ fontSize: "0.7rem" }}>{order?.status}</span>,
        };
        dataSource.push(obj);
      }
    }
    return dataSource;
  };

  const renderOrders = () => {
    let dataSource = buildOrdersDatasource();
    let columns = [
      { title: "Time", dataIndex: "time", key: "time" },
      { title: "B/S", dataIndex: "buySell", key: "buySell" },
      { title: "Strike", dataIndex: "strike", key: "strike" },
      { title: "C/P", dataIndex: "cepe", key: "cepe" },
      { title: "Qty", dataIndex: "qty", key: "qty" },
      { title: "Avg", dataIndex: "avg", key: "avg" },
      { title: "Status", dataIndex: "status", key: "status" },
    ];
    let completedOrdersCount = dayOrders?.filter(
      (order) => order.status === "COMPLETE"
    ).length;
    return (
      <div style={{ paddingRight: "1rem" }}>
        <h3>Orders ({completedOrdersCount})</h3>
        <Table
          dataSource={dataSource}
          columns={columns}
          size="small"
          pagination={{ hideOnSinglePage: true }}
          scroll={{ y: "40vh" }}
        />
      </div>
    );
  };

  const buildClosedPositionsDatasource = () => {
    let dataSource = [];
    dayClosedPositions.forEach((position) => {
      let instrument = subscribedInstruments[position?.exchangeToken];
      let pnl = positionUtils.getClosedPositionsPnL([position]);
      let obj = {
        strike: instrument?.strike,
        cepe: instrument?.instrumentType,
        pnl: (
          <span style={pnl < 0 ? { color: "red" } : { color: "green" }}>
            {pnl}
          </span>
        ),
      };
      dataSource.push(obj);
    });

    return dataSource;
  };

  const renderClosedPositions = () => {
    let dataSource = buildClosedPositionsDatasource();
    let columns = [
      { title: "Strike", dataIndex: "strike", key: "strike" },
      { title: "C/P", dataIndex: "cepe", key: "cepe" },
      { title: "PnL", dataIndex: "pnl", key: "pnl" },
    ];
    return (
      <div>
        <h3>Closed Positions</h3>
        <Table
          dataSource={dataSource}
          columns={columns}
          size="small"
          pagination={{ hideOnSinglePage: true }}
          scroll={{ y: "40vh" }}
        />
      </div>
    );
  };

  const renderTurboUI = () => {
    return (
      <>
        <Row style={{ padding: "1rem" }}>
          <Col span={10}>{renderConfigs()}</Col>
          <Col span={14}>{renderExecutor()}</Col>
        </Row>
        <Row style={{ padding: "1rem" }}>
          <Col span={10}>{renderIndex()}</Col>
          <Col span={4}>{renderRefresh()}</Col>
          <Col span={10}>{renderPnL()}</Col>
        </Row>
        <Row style={{ padding: "1rem" }}>
          <Col span={24}>{renderSellPositions()}</Col>
        </Row>
        <Row style={{ padding: "1rem" }}>
          <Col span={12}>{renderBuyPositions()}</Col>
          <Col span={8}>{renderOrders()}</Col>
          <Col span={4}>{renderClosedPositions()}</Col>
        </Row>
      </>
    );
  };

  return <>{renderTurboUI()}</>;
};

export default TurboComponent;
