import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import AuthApi, { setTokenForAuthApi } from "../../api/auth.js";
import storage from "../../utils/storage.js";
import ROUTES from "../../config/routes.js";
import { Button, Input, Col, Row } from "antd";

const LoginComponent = () => {
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [cookies, setCookie] = useCookies(["token"]);

  const submitLogin = () => {
    AuthApi.post("/api/public/login", {
      username,
      password,
    })
      .then((res) => {
        if (res.status === 200 && res.data.token) {
          let userData = res?.data?.data || {};
          storage.setLocalStorageItem("userData", userData);
          let token = res?.data?.token || "";
          setCookie("token", token, { path: "/" });
          setTokenForAuthApi(token);
          navigate(ROUTES.TURBO);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "50%" }}>
        <Input
          style={{ margin: "8px" }}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
        />
        <Input.Password
          style={{ margin: "8px" }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <Button style={{ margin: "8px" }} onClick={submitLogin}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default LoginComponent;
