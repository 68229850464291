import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import storage from "../../utils/storage.js";
import AuthApi from "../../api/auth.js";
import ROUTES from "../../config/routes.js";
import { Button, Table, Input } from "antd";

const SettingsComponent = () => {
  let navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    getBrokerAccounts();
  }, []);

  const getBrokerAccounts = () => {
    let userObj = storage.getLocalStorageItem("userData");
    let username = userObj?.username;
    AuthApi.get(`/api/secure/getUserAccounts?username=${username}`)
      .then((result) => {
        if (result?.status === 200 && result?.data) {
          let accountsData = result?.data?.data;
          setAccounts(accountsData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBrokerNameFromId = (id) => {
    if (id === 1) {
      return "Zerodha";
    } else if (id === 2) {
      return "Finvasia";
    } else return "Others";
  };

  const updateAuthToken = (account) => {
    AuthApi.post("/api/secure/updateAuthToken", {
      accountId: account.id,
      authToken: account.authToken,
    }).then((res) => {
      alert(res?.data?.message);
      navigate(ROUTES.TURBO);
    });
  };

  const generateAuthToken = (account) => {
    let data = { ...account };
    if (data.apiKey2) {
      data.apiKey = data.apiKey2;
    }
    if (data.password2) {
      data.password = data.password2;
    }

    AuthApi.post("/api/secure/generateAuthToken", data).then((res) => {
      alert(res?.data?.message);
      navigate(ROUTES.TURBO);
    });
  };

  const setAuthToken = (e, account) => {
    let val = e.target.value;
    account.authToken = val;
  };

  const setTOTP = (e, account) => {
    let val = e.target.value;
    account.totp = val;
  };

  const setAPIKey = (e, account) => {
    let val = e.target.value;
    account.apiKey2 = val;
  };

  const setPassword = (e, account) => {
    let val = e.target.value;
    account.password2 = val;
  };

  const renderAuthTokenUpdateCell = (account) => {
    return (
      <>
        <Input
          placeholder="Auth token"
          style={{
            width: 80,
          }}
          size="small"
          onChange={(e) => setAuthToken(e, account)}
          value={account?.authToken}
        />
        <Button
          size="small"
          onClick={() => {
            updateAuthToken(account);
          }}
        >
          UPDATE
        </Button>
      </>
    );
  };

  const renderZerodhaGenerateCell = (account) => {
    let url = `https://kite.trade/connect/login?v=3&api_key=${account.apiKey}`;
    return (
      <>
        <Button
          size="small"
          onClick={() => {
            updateAuthToken(account);
          }}
        >
          <Link to={url} target="_blank">
            GENERATE
          </Link>
        </Button>
      </>
    );
  };

  const renderFinvasiaGenerateCell = (account) => {
    return (
      <>
        <Input
          placeholder="TOTP"
          style={{
            width: 80,
          }}
          size="small"
          onChange={(e) => setTOTP(e, account)}
          value={account?.totp}
        />
        <Button
          size="small"
          onClick={() => {
            generateAuthToken(account);
          }}
        >
          GENERATE
        </Button>
      </>
    );
  };

  const renderGenerateCell = (account) => {
    if (account.brokerid === 1) {
      return renderZerodhaGenerateCell(account);
    } else if (account.brokerid === 2) {
      return renderFinvasiaGenerateCell(account);
    }
  };

  const renderApiKeyCell = (account) => {
    return (
      <>
        <Input
          placeholder="API Key"
          style={{
            width: 80,
          }}
          size="small"
          onChange={(e) => setAPIKey(e, account)}
          value={account?.apiKey2}
        />
      </>
    );
  };

  const renderPasswordCell = (account) => {
    return (
      <>
        <Input
          placeholder="Password"
          style={{
            width: 80,
          }}
          size="small"
          onChange={(e) => setPassword(e, account)}
          value={account?.password2}
        />
      </>
    );
  };

  const renderBrokerSettings = () => {
    let columns = [
      { title: "ID", dataIndex: "id", key: "id", width: 60 },
      {
        title: "Broker",
        dataIndex: "brokerName",
        key: "brokerName",
        width: 60,
      },
      { title: "ClientID", dataIndex: "clientid", key: "clientid", width: 60 },
      { title: "Password", dataIndex: "password", key: "password", width: 60 },
      {
        title: "Display Name",
        dataIndex: "displayName",
        key: "displayName",
        width: 60,
      },
      { title: "API Key", dataIndex: "apiKey", key: "apiKey", width: 60 },
      {
        title: "API Secret",
        dataIndex: "apiSecret",
        key: "apiSecret",
        width: 60,
      },
      {
        title: "Auth Token",
        dataIndex: "authToken",
        key: "authToken",
        width: 60,
      },
      { title: "", dataIndex: "generate", key: "generate", width: 60 },
    ];
    let brokerAccountDataSource = accounts?.map((account) => {
      return {
        id: account?.id,
        clientid: account?.clientid,
        password: account?.password || renderPasswordCell(account),
        brokerName: getBrokerNameFromId(account?.brokerid),
        displayName: account?.displayName,
        apiKey: account?.apiKey || renderApiKeyCell(account),
        apiSecret: account?.apiSecret,
        authToken: renderAuthTokenUpdateCell(account),
        generate: renderGenerateCell(account),
      };
    });
    return (
      <>
        <h3>Broker Settings</h3>
        <Table
          dataSource={brokerAccountDataSource}
          columns={columns}
          size="small"
          pagination={{ hideOnSinglePage: true }}
          scroll={{ y: "40vh" }}
        />
      </>
    );
  };

  const renderSettings = () => {
    return <>{renderBrokerSettings()}</>;
  };

  return renderSettings();
};

export default SettingsComponent;
