import numUtils from "./numUtils.js";

const getClosedPositions = (positions) => {
  let closedPositions =
    positions?.filter((position) => {
      return position.quantity === 0;
    }) || [];
  return closedPositions;
};

const getOpenPositions = (positions) => {
  // TODO: Need to add config for differentiating 'MIS' and 'CNC/NRML' or both!
  let openPositions =
    positions?.filter((position) => {
      return position.quantity !== 0;
    }) || [];
  return openPositions;
};

const getClosedPositionsPnL = (positions) => {
  let totalPnL = 0;
  positions?.forEach((position) => {
    let positionPnL = position?.pnl; // TODO: Test if position?.pnl or position?.m2m
    totalPnL += positionPnL;
  });
  return numUtils.getToFixedNumber(totalPnL);
};

const getOpenPositionsPnL = (positions, ticks, brokerid) => {
  // TODO: This can contain currently open qty + Previously closed and booked pnl as well
  // openPnL = (day_sell_value - day_buy_value) + (quantity * last_price * multiplier)
  let totalPnL = 0;
  positions?.forEach((position) => {
    let symbol = position?.tradingsymbol;
    let exchangeToken = position?.exchangeToken;
    let ltp = ticks[exchangeToken];
    let quantity = position?.quantity;
    let overnightQuantity = position?.overnight_quantity;
    let intradayQty = quantity - overnightQuantity;
    if (quantity && !ltp) {
      //console.log("ERROR!!!!: Unable to get LTP for ", symbol);
      // TODO: MAJOR ERROR! Send notification and act immeditately!
      return 0;
    }
    let multiplier = position?.multiplier;
    let daySellValue = position?.day_sell_value;
    let dayBuyValue = position?.day_buy_value;
    let sellValue = position?.sell_value;
    let buyValue = position?.buy_value;
    //let positionPnL = daySellValue - dayBuyValue + quantity * ltp * multiplier;
    let positionPnL = sellValue - buyValue + quantity * ltp * multiplier;
    if (brokerid === 2) {
      positionPnL = position?.pnl + quantity * (ltp - position?.average_price);
    }
    totalPnL += positionPnL;
  });
  return numUtils.getToFixedNumber(totalPnL);
};

const getTradingSymbolsFromPositions = (positions) => {
  let tradingSymbols =
    positions?.map((position) => {
      return position.tradingsymbol;
    }) || [];
  return tradingSymbols;
};

const getOpenPositionsBySymbol = (positions, symbol) => {
  let filteredPositions = positions.filter((position) => {
    return position.tradingsymbol === symbol;
  });
  return filteredPositions;
};

const positionSelectors = {
  getClosedPositions,
  getOpenPositions,
  getClosedPositionsPnL,
  getOpenPositionsPnL,
  getTradingSymbolsFromPositions,
  getOpenPositionsBySymbol,
};

export default positionSelectors;
