const customPositionOrdering = (positions, subscribedInstruments) => {
  let pePositions =
    positions?.filter((position) => {
      return (
        subscribedInstruments[position?.exchangeToken]?.instrumentType === "PE"
      );
    }) || [];
  let cePositions =
    positions?.filter((position) => {
      return (
        subscribedInstruments[position?.exchangeToken]?.instrumentType === "CE"
      );
    }) || [];

  pePositions.sort((a, b) => {
    let l = subscribedInstruments[a?.exchangeToken]?.strike;
    let r = subscribedInstruments[b?.exchangeToken]?.strike;
    return l < r ? -1 : l > r ? 1 : 0;
  });
  cePositions.sort((a, b) => {
    let l = subscribedInstruments[a?.exchangeToken]?.strike;
    let r = subscribedInstruments[b?.exchangeToken]?.strike;
    return l < r ? -1 : l > r ? 1 : 0;
  });
  return pePositions.concat(cePositions);
};

const userUtils = {
  customPositionOrdering,
};

export default userUtils;
