import axios from "axios";

// TODO: Move below to utils
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const AuthApi = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

let cookieToken = getCookie("token");
if (cookieToken) {
  setTokenForAuthApi(cookieToken);
}

export function setTokenForAuthApi(token) {
  AuthApi.defaults.headers.common["x-access-token"] = token;
}

export function setVelociaxTurboKey(key) {
  AuthApi.defaults.headers.common["x-velociax-turbo-key"] = key;
}

export default AuthApi;
