const getLocalStorageItem = (key) => {
  let itemStr = localStorage.getItem(key);
  if (itemStr) {
    return JSON.parse(itemStr);
  } else {
    return null;
  }
};

const setLocalStorageItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const Storage = {
  getLocalStorageItem,
  setLocalStorageItem,
};

export default Storage;
