import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Layout } from "antd";
import HeaderComponent from "./components/HeaderComponent/index.js";
import MainComponent from "./components/MainComponent/index.js";
import FooterComponent from "./components/FooterComponent/index.js";
import "./App.css";

const App = () => {
  return (
    <Router>
      <div className="App">
        {/* <Button type="primary">Button</Button>
        <h3>{process.env.REACT_APP_API_ENDPOINT}</h3> */}
        <Layout className="layout">
          <HeaderComponent />
          <MainComponent />
          <FooterComponent />
        </Layout>
      </div>
    </Router>
  );
};

export default App;
